import { animated, SpringValue } from '@react-spring/web';
import { Children, useEffect, useRef } from 'react';

import { ScrollInfoEvent, ScrollToChildEvent } from '@once/events/scroll';
import { useSlideSwipePriority } from '@once/hooks/useSlideSwipePriority';

import { ScrollerWrapper, Spacer, ArrowWrapper } from './HorizontalScroller.styles';

export type HorizontalScrollerProps = {
  id?: string;
  className?: string;
  gap?: number;
  snap?: 'start' | 'center' | 'end';
  horizontalPadding?: number;
  verticalPadding?: number;
  animation?: Record<string, SpringValue<number>>;
  children: React.ReactNode;
  center?: boolean;
  noMargin?: boolean;
  arrowLeft?: React.ReactNode;
  arrowRight?: React.ReactNode;
};

export function HorizontalScroller({
  id,
  className,
  gap = 0,
  snap,
  horizontalPadding = 0,
  verticalPadding = 0,
  children,
  animation,
  center,
  noMargin,
  arrowLeft,
  arrowRight,
}: HorizontalScrollerProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  useSlideSwipePriority(containerRef);

  const indexRef = useRef(0);

  const getSpace = (): number => {
    if (horizontalPadding > 0) {
      return gap >= horizontalPadding ? 1 : horizontalPadding - gap;
    }
    return gap;
  };

  const previousSize = useRef(Children.count(children));
  useEffect(() => {
    if (previousSize.current === 0 && Children.count(children) > 0) {
      previousSize.current = Children.count(children);
      containerRef.current?.scrollTo({ left: 0 });
    }
  }, [children]);

  useEffect(() => {
    if (id && containerRef.current) {
      const childrenDOM = Array.from(containerRef.current.children);
      childrenDOM.pop(); // remove Spacer
      const listObserver = childrenDOM.map((child, index) => {
        const observer = new IntersectionObserver(
          (entries) => {
            const [element] = entries;
            if (element.isIntersecting) {
              document.dispatchEvent(new ScrollInfoEvent(id, { index }));
              indexRef.current = index;
            }
          },
          {
            threshold: 0,
            root: containerRef.current,
            // we assume that the children will take more than 50% of the space of the scroller
            rootMargin: '0% -50% 0% -50%',
          },
        );
        observer.observe(child);
        return observer;
      });
      return () =>
        listObserver.forEach((observer) => {
          observer.disconnect();
        });
    }
    return undefined;
  }, [id]);

  useEffect(() => {
    function handleScrollToChild(event: Event): void {
      if (event instanceof ScrollToChildEvent) {
        if (typeof event.detail.index === 'number' && containerRef.current) {
          const child = Array.from(containerRef.current.children)[event.detail.index];
          if (child) {
            child.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          }
        }
      }
    }
    if (id && containerRef.current) {
      document.addEventListener(ScrollToChildEvent.eventName(id), handleScrollToChild);
      return () => document.removeEventListener(ScrollToChildEvent.eventName(id), handleScrollToChild);
    }
    return undefined;
  }, [id]);

  if (!arrowLeft && !arrowRight) {
    return (
      <ScrollerWrapper
        ref={containerRef}
        style={animation}
        as={animation ? animated.div : 'div'}
        className={className}
        $snap={snap}
        $gap={gap}
        $horizontalPadding={horizontalPadding}
        $verticalPadding={verticalPadding}
        $center={center}
        $noMargin={noMargin}
      >
        {children}
        <Spacer $width={getSpace()} />
      </ScrollerWrapper>
    );
  }

  return (
    <div className="relative">
      <ScrollerWrapper
        ref={containerRef}
        style={animation}
        as={animation ? animated.div : 'div'}
        $snap={snap}
        $gap={gap}
        className={className}
        $horizontalPadding={horizontalPadding}
        $verticalPadding={verticalPadding}
        $center={center}
        $noMargin={noMargin}
      >
        {children}
        <Spacer $width={getSpace()} />
      </ScrollerWrapper>
      <ArrowWrapper
        onClick={() => {
          if (id) {
            document.dispatchEvent(new ScrollToChildEvent(id, { index: Math.max(indexRef.current - 1, 0) }));
          }
        }}
        $side="left"
      >
        {arrowLeft}
      </ArrowWrapper>
      <ArrowWrapper
        onClick={() => {
          if (id) {
            document.dispatchEvent(
              new ScrollToChildEvent(id, {
                index: Math.min(indexRef.current + 1, containerRef.current!.children.length - 2),
              }),
            );
          }
        }}
        $side="right"
      >
        {arrowRight}
      </ArrowWrapper>
    </div>
  );
}
