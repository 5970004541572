import { useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';

import { ScrollInfoEvent } from '@once/events';

import { Container, ContainerIndicator, Indicator } from './Indicators.styles';

type IndicatorElementProps = {
  active: boolean;
  colorIndicatorActive: string;
  colorIndicatorNotActive: string;
};

function IndicatorElement({
  active,
  colorIndicatorActive,
  colorIndicatorNotActive,
}: IndicatorElementProps): JSX.Element {
  const spring = useSpring({
    minWidth: active ? '32px' : '16px',
    maxWidth: active ? '32px' : '16px',
    // background: active ? colorIndicatorActive : colorIndicatorNotActive,
  });

  return <Indicator style={{ ...spring, background: active ? colorIndicatorActive : colorIndicatorNotActive }} />;
}

type IndicatorsProps = {
  snapScrollID: string;
  length: number;
  colorIndicatorActive: string;
  colorIndicatorNotActive: string;
  // colorArrow: string;
  // hideArrow?: boolean;
};

export function Indicators({
  snapScrollID,
  length,
  colorIndicatorActive,
  colorIndicatorNotActive,
}: // colorArrow,
// hideArrow = false,
IndicatorsProps): JSX.Element {
  const [currentProductImage, setCurrentProductImage] = useState(0);

  const springRail = useSpring({
    x: currentProductImage > 2 ? `-${(currentProductImage - 3) * 24}px` : `-${0}px`,
  });

  const actualSize = 24 * length;
  const biggerThanContainer = 400 / 2 - actualSize < 0;

  useEffect(() => {
    function handleEvent(e: Event): void {
      if (e instanceof ScrollInfoEvent) {
        setCurrentProductImage(e.detail.index);
      }
    }

    document.addEventListener(ScrollInfoEvent.eventName(snapScrollID), handleEvent);
  }, [snapScrollID]);

  return (
    <Container>
      {/* <ButtonSteps
        $hide={hideArrow}
        onClick={() => {
          if (currentProductImage !== 0) {
            document.dispatchEvent(new ScrollInfoEvent(snapScrollID, { index: currentProductImage - 1 }));
            document.dispatchEvent(new ScrollToChildEvent(snapScrollID, { index: currentProductImage - 1 }));
          }
        }}
      >
        <BackArrowIcon mainColor={colorArrow} height={12} />
      </ButtonSteps> */}
      <div className="w-fit-content m-auto mb-4">
        <ContainerIndicator $biggerThanContainer={biggerThanContainer} style={springRail}>
          {Array(length)
            .fill('k')
            .map((value, index) => (
              <IndicatorElement
                key={value.concat(index.toString())}
                colorIndicatorActive={colorIndicatorActive}
                colorIndicatorNotActive={colorIndicatorNotActive}
                active={index === currentProductImage}
              />
            ))}
        </ContainerIndicator>
      </div>
      {/* <ButtonSteps
        $hide={hideArrow}
        onClick={() => {
          if (currentProductImage !== length - 1) {
            document.dispatchEvent(new ScrollInfoEvent(snapScrollID, { index: currentProductImage + 1 }));
            document.dispatchEvent(new ScrollToChildEvent(snapScrollID, { index: currentProductImage + 1 }));
          }
        }}
      >
        <ArrowIcon mainColor={colorArrow} height={12} />
      </ButtonSteps> */}
    </Container>
  );
}
