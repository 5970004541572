import { RefObject, useEffect, useRef } from 'react';

export function useSlideSwipePriority(containerRef: RefObject<HTMLDivElement>): void {
  const memPos = useRef<{ originalX: number; originalY: number } | null>(null);
  const intention = useRef<'swipe' | 'slide' | null>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const currentContainer = containerRef.current;

    function startMove(event: TouchEvent): void {
      const { clientX, clientY } = event.touches[0];
      memPos.current = { originalX: clientX, originalY: clientY };
    }

    function isolateTouch(e: TouchEvent): void {
      if (!intention.current || intention.current === 'slide') {
        e.stopPropagation();
      } else if (intention.current === 'swipe' && e.cancelable) {
        e.preventDefault();
      }
      const { clientY, clientX } = e.changedTouches[0];
      const { originalX, originalY } = memPos.current ?? {};

      if (originalX && (originalX < clientX - 20 || originalX > clientX + 20)) {
        if (!intention.current) {
          intention.current = 'slide';
        }
      }

      if (originalY && (originalY < clientY - 20 || originalY > clientY + 20)) {
        if (!intention.current) {
          intention.current = 'swipe';
        }
      }
    }

    function endMove(): void {
      memPos.current = null;
      intention.current = null;
    }

    currentContainer.addEventListener('touchstart', startMove, { passive: true });
    currentContainer.addEventListener('touchmove', isolateTouch, { passive: true });
    currentContainer.addEventListener('touchend', endMove, { passive: true });

    return () => {
      currentContainer.removeEventListener('touchstart', startMove);
      currentContainer.removeEventListener('touchmove', isolateTouch);
      currentContainer.removeEventListener('touchend', endMove);
    };
  }, [containerRef]);
}
