import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const BaseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active {
    transform: scale(0.92);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;

    &:active {
      transform: none;
    }
  }
`;

export const AnimatedBaseButton = styled(animated.button)`
  border: none;
  background: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: transform 0.2s ease-in;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.92);
  }
`;
