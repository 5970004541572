import NextImg, { ImageProps as NextImageProps } from 'next/image';

import { imageLoader } from '@once/data/client';
import type { Storefront } from '@once/types';
import { ImageWrapper } from './Image.styles';

export function RawImage(props: NextImageProps): JSX.Element {
  return <NextImg {...props} loader={imageLoader} />;
}

type WrappedImageProps = NextImageProps & {
  wrapper?: {
    width?: string;
    height?: string;
    borderRadius?: number;
    bgColor?: string;
  };
};

export function WrappedImage({ wrapper, ...imgProps }: WrappedImageProps): JSX.Element {
  return (
    <ImageWrapper
      $width={wrapper?.width}
      $height={wrapper?.height}
      $borderRadius={wrapper?.borderRadius}
      $bgColor={wrapper?.bgColor}
      $layout={imgProps.layout}
    >
      <RawImage {...imgProps} />
    </ImageWrapper>
  );
}

export type ImageProps = {
  wrapper?: {
    width?: string;
    height?: string;
    borderRadius?: number;
    bgColor?: string;
  };
  image?: Storefront.Image | null;
  layout?: NextImageProps['layout'];
  objectFit?: NextImageProps['objectFit'];
  objectPosition?: NextImageProps['objectPosition'];
  priority?: boolean;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
};

export function Image({
  wrapper,
  image,
  layout = 'responsive',
  objectFit = 'fill',
  objectPosition = 'center',
  priority,
  onClick,
}: ImageProps): JSX.Element {
  return image ? (
    <WrappedImage
      wrapper={wrapper}
      priority={priority}
      layout={layout}
      objectFit={layout === 'fill' ? objectFit : undefined}
      objectPosition={layout === 'fill' ? objectPosition : undefined}
      src={image.url}
      width={layout !== 'fill' ? image.width ?? undefined : undefined}
      height={layout !== 'fill' ? image.height ?? undefined : undefined}
      alt={image.altText ?? undefined}
      placeholder={image.blurDataURL ? 'blur' : 'empty'}
      blurDataURL={image.blurDataURL ?? undefined}
      onClick={onClick}
    />
  ) : (
    <span />
  );
}
