import { ImageProps as NextImageProps } from 'next/image';
import styled from 'styled-components';

type WrapperProps = {
  $width?: string;
  $height?: string;
  $borderRadius?: number;
  $bgColor?: string;
  $layout?: NextImageProps['layout'];
};

export const ImageWrapper = styled.div<WrapperProps>`
  overflow: hidden;
  min-width: ${(p) => p.$width};
  max-width: ${(p) => p.$width};
  min-height: ${(p) => p.$height};
  max-height: ${(p) => p.$height};
  border-radius: ${(p) => p.$borderRadius ?? 0}px;
  background-color: ${(p) => p.$bgColor};

  /* According to Next's documentation */
  position: ${(p) => (p.$layout === 'fill' ? 'relative' : undefined)};
  display: ${(p) => (p.$layout === 'responsive' ? 'block' : undefined)};

  & img {
    border-radius: ${(p) => p.$borderRadius ?? 0}px;
  }
`;
