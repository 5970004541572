import styled from 'styled-components';

import { BaseButton } from './Base';

export type RoundButtonProps = {
  $bgColor?: string;
  $borderColor?: string;
  $size: number;
};

export const RoundButton = styled(BaseButton)<RoundButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.$bgColor};
  border: ${(p) => (p.$borderColor ? `1px solid ${p.$borderColor}` : undefined)};
  max-width: ${(p) => p.$size}px;
  max-height: ${(p) => p.$size}px;
  min-width: ${(p) => p.$size}px;
  min-height: ${(p) => p.$size}px;
  border-radius: ${(p) => p.$size}px;

  &:disabled {
    opacity: 0.4;
  }
`;
