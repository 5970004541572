import { createRef, RefObject, useEffect, useRef, useState } from 'react';
import { animated, useSpring, useSprings } from '@react-spring/web';
import type { Storefront } from '@once/types';
import { ScrollInfoEvent } from '@once/events';

import { HorizontalScroller } from '@once/components/Container/HorizontalScroller';
import { RawImage } from '@once/components/Primitive/Image';
import { Container, ContainerHorizontal, LinearBottom } from './HalfContentCarousel.styles';
import { Indicators } from '../Indicators';
import { HalfContentCard } from './HalfContentCard';

export type HalfContentCarouselScreen = {
  background: string;
  image: Storefront.Image & { objectPosition: string };
  imageHeight?: string;
  overtitle?: string;
  title: string;
  text: string[];
  cta?: { text: string; handle: string };
};

type HalfContentCarouselProps = {
  screens: HalfContentCarouselScreen[];
  carouselID: string;
  indicatorColor?: string;
};

export function HalfContentCarousel({ screens, carouselID, indicatorColor }: HalfContentCarouselProps): JSX.Element {
  const [currentProductImage, setCurrentProductImage] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const screensRef = useRef<Array<RefObject<HTMLDivElement>>>([]);

  if (screensRef.current.length !== screens.length) {
    screensRef.current = Array(screens.length)
      .fill(' ')
      .map((_, i) => screensRef.current[i] || createRef());
  }

  const indicatorsSpring = useSpring({
    // margin + padding + size of content
    bottom: `calc(5rem + 0.5rem + ${contentHeight}px)`,
  });

  const animatedScreens = useSprings(
    screens.length,
    screens.map((screen, i) => ({
      marginLeft: currentProductImage + 1 === i ? '-1.5rem' : currentProductImage - 1 === i ? '1.5rem' : '0rem',
    })),
  );

  useEffect(() => {
    function handleEvent(e: Event): void {
      if (e instanceof ScrollInfoEvent) {
        setCurrentProductImage(e.detail.index);
      }
    }

    document.addEventListener(ScrollInfoEvent.eventName(carouselID), handleEvent);
  }, [carouselID]);

  useEffect(() => {
    const elementRef = screensRef.current[currentProductImage].current;
    if (elementRef) {
      const observer = new ResizeObserver(([entry]) => {
        setContentHeight(entry.contentRect.bottom);
      });
      observer.observe(elementRef);
      return () => observer.disconnect();
    }
    return undefined;
  }, [currentProductImage]);

  return (
    <Container>
      <ContainerHorizontal>
        <HorizontalScroller id={carouselID} snap="center" className="w-full h-full">
          {animatedScreens.map((styles, i) => {
            const screen = screens[i];
            return (
              <div key={screen.title} className={`w-full h-full ${screen.background}`}>
                {screen.image.url ? (
                  <div className="w-full h-full relative responsive">
                    <RawImage
                      src={screen.image.url}
                      layout="fill"
                      objectFit="contain"
                      objectPosition={screen.image.objectPosition}
                      alt={screen.image.altText ?? undefined}
                      placeholder={screen.image.blurDataURL ? 'blur' : 'empty'}
                    />
                    <animated.div
                      ref={screensRef.current[i]}
                      className="absolute bottom-0 mb-20 px-4 z-10 w-full"
                      style={styles}
                    >
                      <HalfContentCard
                        key={screen.title}
                        title={screen.title}
                        overtitle={screen.overtitle}
                        content={screen.text}
                        cta={screen.cta}
                      />
                    </animated.div>
                  </div>
                ) : undefined}
              </div>
            );
          })}
          <animated.div className="w-[fit-content] absolute  left-0 right-0 m-auto z-10" style={indicatorsSpring}>
            <Indicators
              snapScrollID={carouselID}
              length={screens.length}
              colorIndicatorActive={indicatorColor ?? `var(--medium-dark)`}
              colorIndicatorNotActive="#21212152"
              // colorArrow={indicatorColor ?? 'var(medium-dark)'}
            />
          </animated.div>
        </HorizontalScroller>
        <LinearBottom />
      </ContainerHorizontal>
    </Container>
  );
}
