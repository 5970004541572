import { forwardRef } from 'react';
import router from 'next/router';

import { Text } from 'src/custom/components/common/Text';
import { Flex } from '@once/components/Layout/Flex';
import { ArrowIcon } from '@once/components/icons';
import { BaseButton } from '@once/components/Primitive/Button';

import { Divider } from './HalfContentCarousel.styles';

export type HalfContentCardProps = {
  overtitle?: string;
  title: string;
  content: string[];
  cta?: { text: string; handle: string };
};

export const HalfContentCard = forwardRef<HTMLDivElement, HalfContentCardProps>(function HalfContentCard(
  { overtitle, title, content, cta },
  ref,
) {
  return (
    <div
      className="w-[100%] flex flex-col gap-2 bg-white box-shadow[0px 2px 4px rgba(0, 0, 0, 0.04)] rounded-xl p-4"
      ref={ref}
    >
      <div className="bg-dark rounded-full w-[fit-content] text-white px-3 py-1 absolute left-0 right-0 mx-auto -mt-4 -translate-y-1/2">
        <Text size="small" bold maxLines={2}>
          {overtitle}
        </Text>
      </div>
      <div className="py-3">
        <Text as="h3" size="subtitle" bold maxLines={2}>
          {title}
        </Text>
      </div>
      {content.map((text) => (
        <Text size="base" color="grey" key={text}>
          {text}
        </Text>
      ))}

      {cta ? (
        <>
          <BaseButton
            onClick={() => {
              router.push(cta.handle);
            }}
          >
            <Flex justifyContent="space-between" direction="row" alignItems="center">
              <Text size="base" color="var(--light-blue)">
                {cta.text}
              </Text>
              <ArrowIcon height="12px" width="12px" mainColor="var(--light-blue)" />
            </Flex>
          </BaseButton>
          <Divider />
        </>
      ) : null}
    </div>
  );
});
