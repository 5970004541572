import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { BaseButton } from '@once/components/Primitive/Button';

type ContainerIndicatorProps = {
  $biggerThanContainer: boolean;
};

export const ContainerIndicator = styled(animated.div)<ContainerIndicatorProps>`
  width: ${(p) => (p.$biggerThanContainer ? '100%' : 'fit-content')};
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 auto;
`;

export const Indicator = styled(animated.div)`
  height: 2px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type ButtonStepsProps = {
  $hide: boolean;
};

export const ButtonSteps = styled(BaseButton)<ButtonStepsProps>`
  padding: 16px;
  opacity: ${(p) => (p.$hide ? 0 : 1)};
`;
