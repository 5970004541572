import styled from 'styled-components';

import { BaseButton } from './Base';

export type ButtonProps = {
  $bgColor: string;
  $border?: string;
  $borderRadius?: number;
  $padding?: string;
  $justifyContent?: string;
  $width?: string;
  $height?: string;
  $transition?: string;
};

export const Button = styled(BaseButton)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.$justifyContent ?? 'center'};
  gap: 12px;
  background: ${(p) => p.$bgColor};
  border: ${(p) => p.$border};
  border-radius: ${(p) => p.$borderRadius ?? 50}px;
  padding: ${(p) => p.$padding ?? '8px 16px'};
  transition: ${(p) => p.$transition};
  width: ${(p) => p.$width};
  height: ${(p) => p.$height};

  &:disabled {
    opacity: 0.4;
  }
`;

export type CTAButtonProps = {
  bgColor: string;
  border?: string;
  borderRadius?: number;
  padding?: string;
  transition?: string;
  width?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
};

export function CTAButton({
  bgColor = 'var(--primary-color)',
  border,
  borderRadius,
  padding,
  transition,
  width,
  disabled = false,
  onClick,
  onMouseEnter,
  children,
  type = 'button',
}: CTAButtonProps): JSX.Element {
  return (
    <Button
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
      $bgColor={bgColor}
      $border={border}
      $borderRadius={borderRadius}
      $padding={padding}
      $width={width}
      $transition={transition}
    >
      {children}
    </Button>
  );
}
