import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { BaseButton } from '@once/components/Primitive/Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  position: relative;
`;

export const ContainerIndicator = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Indicator = styled(animated.div)`
  height: 2px;
`;

export const ContainerHorizontal = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LinearBottom = styled.div`
  height: 108px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`;

export const ButtonSteps = styled(BaseButton)`
  padding: 16px;
`;

export const CardContainer = styled.div`
  padding: 24px;
  width: calc(100vw - 16px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: var(--text-primary-color);
  box-shadow: 0px 1px 2px rgba(70, 84, 97, 0.04), 0px 2px 8px -4px rgba(70, 84, 97, 0.08);
  gap: 16px;
  overflow: hidden;
`;

export const Divider = styled.hr`
  margin: 0px;
  border: 1px solid var(--light-blue);
`;
