import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

type ScrollerWrapperProps = {
  $snap?: 'start' | 'center' | 'end';
  $center?: boolean;
  $gap: number;
  $horizontalPadding: number;
  $verticalPadding: number;
  $noMargin?: boolean;
};

const getSnap = (snap: 'start' | 'center' | 'end' | undefined, gap: number): FlattenSimpleInterpolation | undefined =>
  snap
    ? css`
        scroll-snap-type: x mandatory;

        & > * {
          scroll-snap-align: ${snap};
          scroll-snap-stop: always;
          scroll-margin: ${gap}px;
        }
      `
    : undefined;

export const ScrollerWrapper = styled.div<ScrollerWrapperProps>`
  display: flex;
  flex-wrap: nowrap;
  align-items: ${(p) => p.$center && 'center'};
  margin: ${(p) => (p.$noMargin ? '0' : '0 auto')};
  overflow: hidden;
  overflow-x: auto;
  gap: ${(p) => p.$gap}px;
  max-width: 100%;
  padding: ${(p) => p.$verticalPadding}px 0;

  & > * {
    flex: 0 0 auto;
  }

  & > :first-child {
    margin-left: ${(p) => p.$horizontalPadding}px;
  }

  ${(p) => getSnap(p.$snap, p.$gap)};

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type SpacerProps = {
  $width: number;
};

export const Spacer = styled.span<SpacerProps>`
  display: block;
  height: 1px;
  min-height: 1px;
  min-width: ${(p) => p.$width}px;
  max-width: ${(p) => p.$width}px;
  width: ${(p) => p.$width}px;
  background: none;
`;

type ArrowWrapperProps = {
  $side: string;
};
export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  position: absolute;
  top: 30%;
  left: ${(p) => (p.$side === 'left' ? '0px' : null)};
  right: ${(p) => (p.$side === 'right' ? '0px' : null)};
`;
